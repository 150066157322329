import React from "react";

export default function TestIndex() {
  return (
    <>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8"></div>
        <div className="col-md-2"></div>
      </div>
    </>
  );
}
